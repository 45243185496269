


















































import { type PropType, defineComponent } from "@vue/composition-api";
import { buttonVariants } from "@/lib/pfg/components/button";
import Card from "@/lib/pfg/vue2/card/Card.vue";
import CardContent from "@/lib/pfg/vue2/card/CardContent.vue";
import CardHeader from "@/lib/pfg/vue2/card/CardHeader.vue";
import CardTitle from "@/lib/pfg/vue2/card/CardTitle.vue";
import Input from "@/lib/pfg/vue2/input/Input.vue";
import Label from "@/lib/pfg/vue2/label/Label.vue";
import Textarea from "@/lib/pfg/vue2/textarea/Textarea.vue";
import type { RouteProps } from "@/router/types";

export default defineComponent({
	components: { Input, Card, CardHeader, CardTitle, CardContent, Label, Textarea },
	props: {
		memberId: {
			type: Number as PropType<RouteProps<"member">["memberId"]>,
			required: true,
		},
	},
	setup(props) {
		return { props, buttonVariants };
	},
});
